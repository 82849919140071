import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Error() {
  const {t} = useTranslation()
  return(
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('404 - Page not found')}</title>
        <link rel="canonical" href="https://dextermemory.com/" />
      </Helmet>
      <div className="h-screen">
        <div className="h-full container mx-auto flex flex-col md:flex-row items-center my-0">
          <div className="flex flex-col mx-auto justify-center items-start p-0 md:px-6 md:py-6 text-center">
            <p><strong>{t('404 - Page not found')}</strong></p>
          </div>
        </div>
      </div>
    </div>
  )
}
